import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const Copyright = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://www.sutherlandglobal.com/'>
                Sutherland
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Typography variant='h6' align='center' gutterBottom>
                Thank you!
      </Typography>
      <Typography variant='subtitle1' align='center' color='textSecondary' component='p'>
                Powered By - SmartLeap &trade;
      </Typography>
      <Copyright />
    </footer>
  )
}
