import React from "react";
import AudioAnalyzer from "./Analyzer";
import {
  AudioInputStream,
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { Translate } from "../libs/CognitiveCloud";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import MicIcon from "@material-ui/icons/Mic";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CoreAi from "./CoreAi";
import Typer from "./typer";
import Hoc from "./Hoc/hoc";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import BackupIcon from "@material-ui/icons/Backup";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress from "@material-ui/core/LinearProgress";
import StopIcon from '@material-ui/icons/Stop';
// import { functions } from "lodash"


const SubscriptionKey = process.env.REACT_APP_AZ_KEY;
const ServiceRegion = process.env.REACT_APP_AZ_REGION;
const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
const speechConfig = SpeechConfig.fromSubscription(
  SubscriptionKey,
  ServiceRegion
);
speechConfig.speechRecognitionLanguage = "en-US";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
    overflowX: "auto",
  },

  control: {
    padding: theme.spacing(2),
  },
  pink: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  textField: {
    padding: theme.spacing(0.5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  progressAnim: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [audio, setAudio] = React.useState(null);
  const [mic, setMic] = React.useState("primary");
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [progressColor,setProgressColor]=React.useState("primary")
  const [state, setState] = React.useState(true);
  const [text, setText] = React.useState("");
  const [partialText, setPartial] = React.useState("");
  const [arab, setArab] = React.useState("");
  const [spain, setSpain] = React.useState("");
  const [german, setGerman] = React.useState("");
  const [greek, setGreek] = React.useState("");
  const [english, setEnglish] = React.useState("");
  const [typingInProgress, setTypingInProgressFlag] = React.useState(false);
  
  
  const [uploadRecognizer,setUploadRecognizer]=React.useState({})
  var lastText = "";

  
  const initializeState = (audio) => {
    if (audio) setAudio(audio);
    setPartial("");
    setMic("secondary");
    setState(false);
    setText("");
    setArab("");
    setSpain("");
    setGerman("");
    setEnglish("");
    setGreek("");
    lastText = "";
  };

  const resetState = (audio) => {
    if (audio) setAudio(null);
    setPartial("");
    setMic("primary");
    setState(true);
  };
  const getMicrophone = async () => {
    const maudio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    initializeState(maudio);
    voiceRecognition();
  };

  const stopMicrophone = () => {
    audio.getTracks().forEach((track) => track.stop());
    resetState(true);
    voiceRecognition();
  };

  const TranslateAndSetText = (text) => {
    if (text.length > 1) {
      lastText +=  '\n' + text;
      lastText = lastText.trim();
      setText(lastText);
      Translate(lastText, "ar")
        .then((data) => {
          setArab(data);
        })
        .catch((err) => console.log(err));

      Translate(lastText, "es")
        .then((data) => {
          setSpain(data);
        })
        .catch((err) => console.log(err));

      Translate(lastText, "de")
        .then((data) => {
          setGerman(data);
        })
        .catch((err) => console.log(err));

      Translate(lastText, "el")
        .then((data) => {
          setGreek(data);
        })
        .catch((err) => console.log(err))
        
      Translate(lastText, "en")
      .then((data) => {
        setEnglish(data);
      })
      .catch((err) => console.log(err))
    }
  };

  const toggleMicrophone = () => {
    audio ? stopMicrophone() : getMicrophone();
  };

  const typingFocus = () => {
    setTypingInProgressFlag("true");
    if (audio) {
      stopMicrophone();
    }
  };

  const typingInput = (textip) => {
    setText(textip);
  };

  const doneTyping = () => {
    setTypingInProgressFlag(false);
    lastText = "";
    TranslateAndSetText(text);
  };

  const voiceRecognition = () => {
    var recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    recognizer.recognizing = (s, e) => {
      setPartial(e.result.text);
    };
    recognizer.recognized = (s, e) => {
      setPartial("");
      if (e.result.text) {
        TranslateAndSetText(e.result.text);
      }
    };
    recognizer.canceled = (s, e) => {};
    recognizer.sessionStarted = (s, e) => {};
    recognizer.sessionStopped = (s, e) => {};
    recognizer.speechStartDetected = (s, e) => {};
    recognizer.speechEndDetected = (s, e) => {};
    if (state === true) {
      recognizer.startContinuousRecognitionAsync(
        () => {},
        (err) => {
          console.error(err);
          recognizer.close();
          recognizer = null;
        }
      );
    } else {
      try {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            recognizer.close();
            recognizer = null;
          },
          (err) => {
            console.log("Error");
            recognizer.close();
            recognizer = null;
          }
        );
      } catch (error) {
        recognizer.close();
        recognizer = null;
      }
    }
  };

  const stopTranscribing=()=>{
      uploadRecognizer.close()
      setProgressColor('primary')
      setLoadingFile(false)
      setPartial('')
  }

  const uploadDoc = async (e) => {
      console.log('clicked on upload doc')
    e.preventDefault();
    const files = e.target.files;
    
    const reader = new FileReader();
    console.log(e.target.files);
    const pushStream = AudioInputStream.createPushStream();
    reader.onload = function (e) {
      // Do something with the file
      // E.g. Send it to the cloud
      ////////////////////////////////////////
      //         console.log(e.target.result);
      //         const encoder = new Lame({
      //             "output":"buffer"

      //         }).setBuffer(e.target.result.slice());

      //         encoder.decode()
      // .then(() => {
      //     // Encoding finished
      //     const buffer = encoder.getBuffer();
      //     console.log(buffer)
      //     pushStream.write(buffer);
      // })
      // .catch((error) => {
      //     // Something went wrong
      //     console.log(error)
      // });
      // var context = new AudioContext()
      // context.decodeAudioData(e.target.result.slice(), function (buffer){

      //     var wav = toWav(buffer)
      //     console.log(wav)
      // })
      ///////////////////////////////////////
      pushStream.write(e.target.result.slice());
      console.log(e.target.result);
    };
    reader.onloadend = function (e) {
      console.log("loading complete");
      lastText=''
      setPartial('')
      setText('')
      setLoadingFile(true);
      pushStream.close();
    };

    //   if(files[0].type==="audio/wav"){
    console.log(files[0]);
    reader.readAsArrayBuffer(files[0]);
    var audioConfigFromFile = AudioConfig.fromStreamInput(pushStream);
    var recognizer = new SpeechRecognizer(speechConfig, audioConfigFromFile);
    
    ///////////////////////////////////////////////////
    //   recognizer.recognizeOnceAsync(
    //     function (result) {
    //         console.log("recognition done")
    //       console.log(result.privText);
    //     setLoadingFile(false)
    //       TranslateAndSetText(result.privText)
    //       recognizer.close();
    //       recognizer = undefined;
    //     },
    //     function (err) {
    //       console.trace("err - " + err);

    //       recognizer.close();
    //       recognizer = undefined;
    //     });
    //   }
    ///////////////////////////////////////////////////
    recognizer.startContinuousRecognitionAsync(
      function () {},
      function (err) {
        console.trace("err - " + err);
      }
    );
  

  setUploadRecognizer(recognizer)
    // The event recognized signals that a final recognition result is received.
    recognizer.recognized = function (s, e) {
      console.log(e);
      // console.log("recognized text", e.result.text);
      setPartial("");
      setPartial(e.result.text);
      
      TranslateAndSetText(e.result.text);
    };
    recognizer.speechEndDetected = (s, e) => {
      console.log(e);
      // console.log(s);
      setProgressColor('primary')
      setLoadingFile(false)
      setPartial('')
    };
    recognizer.speechStartDetected = (s, e) => {
      console.log(e);
      // console.log(s);
      setProgressColor('secondary')
    };
  };

  const renderStopIcon=()=>{
      if(loadingFile){
          return <IconButton
          className={classes.pink}
          color={mic}
          aria-label="stop Transcribing"
          component="span"
          onClick={stopTranscribing}
        >
          <StopIcon fontSize="large" color="secondary" />
        </IconButton>
      }
      else{
          return <><label htmlFor="upload-button">
          <IconButton
            className={classes.pink}
            color={"primary"}
            aria-label="start uploading"
            component="span"
          >
            <BackupIcon fontSize="large" />
          </IconButton>
          </label>
          <input
              type="file"
              name="file"
              id="upload-button"
              style={{ display: "none" }}
              onChange={uploadDoc}
            />
            </>

      }
  }

  const renderMicrophone = (typingInProgress) => {
    if (!typingInProgress) {
      return [
        <Grid key={"1"} container spacing={0}>
          <Grid item xs={1}>
            <IconButton
              className={classes.pink}
              color={"primary"}
              aria-label="start listening"
              component="span"
              onClick={() => typingFocus()}
            >
              <KeyboardIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid key={"2"} item xs={1}>
            <IconButton
              className={classes.pink}
              color={mic}
              aria-label="start listening"
              component="span"
              onClick={toggleMicrophone}
            >
              <MicIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid key={"3"} item xs={1}>
            
            {renderStopIcon()}
              
          </Grid>
          <Grid key={"4"} item xs={8}>
            {audio ? (
              <AudioAnalyzer className={classes.pink} audio={audio} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>,
      ];
    }
  };
  return (
    <Hoc>
      <Container maxWidth="md">
        <br />
        <Paper elevation={6} className={classes.paper}>
          {renderMicrophone(typingInProgress)}
          <Typography color="textSecondary" variant="subtitle2" gutterBottom>
            {partialText}
          </Typography>
          {(()=>{
            if(loadingFile){

          return<div className={classes.progressAnim}>
            
            <LinearProgress color={progressColor}/>
          </div>
            }
          })()}
          <Divider variant="middle" />

          <Typer
            text={text}
            typingStatus={typingInProgress}
            typingFocus={typingFocus}
            typingInput={typingInput}
            doneTyping={doneTyping}
          />
        </Paper>
        <br />
        {text ? (
          <CoreAi
            Text={text}
            uploadProgress={loadingFile}
            renderMicrophone={renderMicrophone}
            typingInProgress={typingInProgress}
            typingFocus={typingFocus}
            typingInput={typingInput}
            doneTyping={doneTyping}
            arab={arab}
            spain={spain}
            german={german}
            greek={greek}
            english={english}
          />
        ) : (
          <Skeleton width={"100%"} height={300} />
        )}
      </Container>

      {/* <Convert renderMicrophone={renderMicrophone}
    text={text} typingInProgress={typingInProgress} typingFocus={typingFocus} typingInput={typingInput} doneTyping={doneTyping}
    arab={arab} spain={spain} german={german}/> */}
    </Hoc>
  );
};
