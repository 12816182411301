import React from 'react'
import SentenceCard from './SentenceCard'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import GaugeChart from 'react-gauge-chart'
import Box from '@material-ui/core/Box'
import { SentimentAnalysis } from '../libs/CognitiveCloud'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  root: {

    verticalAlign: 'middle'
  },
  label: {

    marginLeft: '102px'
  },
  title: {
    fontSize: 14
  },
  lable: {
    marginLeft: '61px',
    paddingLeft: '39px'

  },
  magnitude: {
    backgroundColor: '#29b6f6',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '15px',
    color: 'white'
  },
  scoreRange: {

    width: '100%',
    padding: '7px',
    marginTop: '16px'
  },
  rangePositive: {
    backgroundColor: '#388e3c',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'white'

  },
  rangeNegative: {
    backgroundColor: '#e53935',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'white'

  },
  rangeNeutral: {
    backgroundColor: '#ffe57f',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'black'

  },
  neutral: {
    backgroundColor: '#ffe57f',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '15px',
    color: 'black'
  },
  positive: {
    backgroundColor: '#388e3c',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '15px',
    color: 'white'
  },
  negative: {
    backgroundColor: '#e53935',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '15px',
    color: 'white'
  },
  rangePaper: {
    backgroundColor: theme.palette.background.paper
  }
})
)

export default (props) => {
  const classes = useStyles()

  const [score, setScore] = React.useState(0.0)
  const [lable, setLable] = React.useState('Meter')
  const [color, setColor] = React.useState('textPrimary')
  const [docScore, setDocSure] = React.useState(0.0)
  const [magnitude, setMagnitude] = React.useState(0.0)
  const [scoreColor, setScoreColor] = React.useState(classes.neutral)
  const [list, setList] = React.useState([])
  const [last, setLast] = React.useState('')

  const CoreComponent = () => {
    if (props.Text && lable !== 'Meter') {
      return (
        <>
          <Card className={classes.root}>
            <CardContent>
              <Paper elevation={3}>
                <Typography variant='h5' gutterBottom>
                                    Overall Sentiment
                </Typography>
                <Divider />

                <Grid container className={classes.root} align='left' justify='space-between' spacing={1}>

                  <Grid item xs={6}>
                    <GaugeChart
                      id='sentimentmeter'
                      nrOfLevels={100}
                      arcsLength={[0.5, 0.5, 0.5]}
                      colors={['#e53935', '#ffe57f', '#388e3c']}
                      hideText
                      animate
                      percent={score}
                      arcPadding={0.02}
                      style={{ maxWidth: 292 }}

                    />

                        <Typography variant='h6' className={classes.label} color={color} gutterBottom>
                          {lable}
                        </Typography>
                  </Grid>
                  <Grid item xs={3} align='left'>
                    <Typography align='center' variant='button'>Score</Typography>
                    <Box align='center' className={scoreColor}>
                      <Typography variant='h6' display='block'><strong>{docScore}</strong></Typography>
                    </Box>

                  </Grid>

                  <Grid item xs={3} align='left'>
                    <Typography align='center' variant='button'>Magnitude</Typography>
                    <Box align='center' className={classes.magnitude}>
                      <Typography variant='h6' display='block'><strong>{magnitude}</strong></Typography>
                    </Box>

                  </Grid>

                </Grid>

                <Grid container align='left' alignItems='flex-start'>
                  <Grid item>
                    <Box className={classes.scoreRange}><Typography><strong>Score Range</strong></Typography></Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.rangeNegative}><Typography><strong>-1.0 to -0.25</strong></Typography></Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.rangeNeutral}><Typography><strong>-0.25 to 0.25</strong></Typography></Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.rangePositive}><Typography><strong>0.25 &nbsp;to 1.0</strong></Typography></Box>
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <Typography variant='h5' gutterBottom>
                                Sentences
              </Typography>
              <Divider />
              <br />

              <SentenceCard sentence={list} />
            </CardContent>
          </Card>
        </>
      )
    } else {
      return <Skeleton width='100%' height={200} />
    }
  }

  React.useEffect(() => {
    let isSubscribed = true
    if (props.Text !== last) {
      SentimentAnalysis(props.Text).then(data => {
        console.log(data)
        if (isSubscribed) {
          setLast(props.Text)
          setList(data.sentence)
          const number = data.result.score
          switch (true) {
            case number >= 0.25 && number < 1.0:
              setScore(0.9)
              setLable('POSITIVE')
              setScoreColor(classes.positive)
              setColor('primary')
              break
            case number >= -0.25 && number < 0.25:
              setScore(0.5)
              setLable('NEUTRAL')
              setScoreColor(classes.neutral)
              setColor('textSecondary')
              break
            case number >= -1.0 && number < -0.25:
              setScore(0.1)
              setLable('NEGATIVE')
              setScoreColor(classes.negative)
              setColor('secondary')
              break
            default:
              setScore(0.0)
              break
          }

          setDocSure(data.result.score)
          setMagnitude(data.result.magnitude)
        }
      }).catch(err => console.error(err))
    }
    return () => isSubscribed = false
  }, [props.Text, classes.negative, classes.positive, classes.neutral, last])
  return (
    <CoreComponent />
  )
}
