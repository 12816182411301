import React from "react"

import { useState,useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import {Chip,Paper} from '@material-ui/core';


import StarsIcon from '@material-ui/icons/Stars';

import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Skeleton } from '@material-ui/lab';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import Hoc from "./Hoc/hoc"
import "../assets/css/tags.css"


import post from "./post/post"
import "../assets/css/wordlist.css"


const useStyles = makeStyles((theme)=>({
    root: {
      display: 'flex',
     justifyContent: 'center',
     flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5)
    //  margin: 0
  },
  textfield: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: '10px 0px 0px 250px',

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

  





export default (props) => {
  const [repoWords, setRepoWords] = useState([])
    const [chipData, setChipData] = useState([])
    const [newWord, setNewWord] = useState('')
  const classes = useStyles()






useEffect(() => {
  console.log("fresh")
  post('https://6c8vjlosu7.execute-api.us-east-1.amazonaws.com/default/dougdemo',
        {
          keyname: "0000",
          // keywords: words,
          type:"get"
        }
      ).then((data) => {
        console.log("resp")
        setRepoWords(data.Item.keywords)
        console.log(data) }).catch()
},[])  
useEffect(()=>{
  
  
  setChipData(repoWords.map((each, index) => {
    return ({
      key: index, label: each
    })
  }))

}, [repoWords])

 

  useEffect(() => {
    let words = chipData.map(each => each.label)
    console.log("chipdata")
    console.log(words)
    if (words.length>0){

      post('https://6c8vjlosu7.execute-api.us-east-1.amazonaws.com/default/dougdemo',
        {
          keyname: "0000",
          keywords: words,
          type:"put"
        }
      ).then((data) => {
        console.log("resp")
        console.log(data) }).catch()
    }
    console.log("words")
    console.log(words)

    // localStorage.setItem('chip', words)
}, [chipData])

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key))
  };

  const handleSubmit = () => {
    if (newWord !== null && newWord.trim().length !== 0) {
      const addition = {}


      addition.key = chipData.length
      addition.label = newWord
      setChipData([...chipData, addition])
      setNewWord('')
    }
  }
  const keyPress=(e)=>{
    
    if(e.keyCode === 13){
      handleSubmit()
      e.preventDefault()
    }
  }

  return (
    <Hoc>
      <Paper elevation={6} component='form' className={classes.textfield}>

        <InputBase
          className={classes.input}
          placeholder='Add a word'
          inputProps={{ 'aria-label': 'Add a word' }}
          value={newWord}
          onChange={(e) => { setNewWord(e.target.value) }}
          onSubmit={handleSubmit}
          onKeyDown={(e) => { keyPress(e) }}

        />

        <Divider className={classes.divider} orientation='vertical' />
        <IconButton color='primary'  className={classes.iconButton} aria-label='directions' onClick={handleSubmit}>
          <AddCircleRoundedIcon />
        </IconButton>
      </Paper>

      {chipData.length > 0 ? <Paper elevation={3} component='ul' className={classes.root}>
        {chipData.map((data) => {
        return (

            <li key={data.key}>
              <Chip
                icon={<StarsIcon />}
                label={data.label}
                onDelete={handleDelete(data)}
                className={classes.chip}
                color='secondary'
                size='medium'
              />
            </li>
          );
        })}
      </Paper> : <Skeleton animation='wave' variant='rect' width="100%" height={48} />}

    </Hoc>
  )
    
}
