import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Hoc from './Hoc/hoc'

const useStyles = makeStyles(theme => ({

  paper: {
    width: '100%',
    overflowX: 'auto'

  },

  pink: {

    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  textField: {

    padding: theme.spacing(0.5)

  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}))

export default (props) => {
  const classes = useStyles()
  if (props.typingStatus) {
    return (

      <Hoc>

        <TextField
          className={classes.textField}
          id='Input'
          label='Input Text'
          variant='outlined'
          fullWidth
          autoFocus
          rows='4'
          multiline
          onChange={e => props.typingInput(e.target.value)}
          value={props.Text}
          InputLabelProps={{
            shrink: true
          }}
          margin='normal'
          InputProps={{

            style: { fontSize: 25 },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='primary'
                  onClick={(e) => {
                    props.doneTyping(e.target.value)
                  }}
                >
                  <CheckCircleIcon fontSize='large' />
                </IconButton>

              </InputAdornment>
            )
          }}
        />
      </Hoc>
    )
  } else {
    return <Hoc>

      <TextField
        className={classes.textField}
        id='outlined-basic'
        label='Input Text'
        variant='outlined'
        fullWidth
        multiline
        value={props.text}
        InputLabelProps={{
          shrink: true
        }}
        margin='normal'
        InputProps={{
          readOnly: true,
          style: { fontSize: 25 }
        }}
      />
           </Hoc>
  }
}
