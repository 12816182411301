import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import ArabComponent from "./ArabTranslate"
import SpanishComponent from "./SpanishComponent"
import GermanComponent from "./GermanComponent"
import EnglishComponent from "./EnglishComponent"
import GreekComponent from "./GreekComponent"
import CoreAi from "./CoreAi"



export default (props) => {
  return <>
    <br />

    {/* <Paper className={classes.paper}>
      {props.renderMicrophone(props.typingInProgress)}
        <Typography color="textSecondary" variant="subtitle2" gutterBottom>
          {props.partialText}
        </Typography>
        <Divider variant="middle" />
        <Typer text={props.text}
        typingStatus={props.typingInProgress}
        typingFocus={props.typingFocus} typingInput={props.typingInput} doneTyping={props.doneTyping}/>

      </Paper> */}
      <br />
      {props.greek ? (
        <GreekComponent uploadProgress={props.uploadProgress} Text={props.greek} />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={100} />
      )}
      
      <br />
      {props.arab ? (
        <ArabComponent uploadProgress={props.uploadProgress} Text={props.arab} />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={100} />
      )}
      <br />
      {props.spain ? (
        <SpanishComponent uploadProgress={props.uploadProgress} Text={props.spain} />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={100} />
      )}
      <br />
      {props.german ? (
        <GermanComponent uploadProgress={props.uploadProgress} Text={props.german} />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={100} />
      )}
     
      <br />
      
      {props.english ? (
        <EnglishComponent uploadProgress={props.uploadProgress} Text={props.english} />
      ) : (
        <Skeleton variant="rect" width={"100%"} height={100} />
      )}
      <br />
      <br />
      {props.text ? (
        <CoreAi Text={props.text} />
      ) : (
        null
      )}
      
    
  </>
}
