import React from 'react'
import Footer from './components/Footer'
import Header from './components/Header'
import Recorder from './components/Recorder'
import './App.css'

function App () {
  return (
    <>
      <Header />
      <audio id='audioPlayback'>
        <source id='audioSource' type='audio/mp3' src='' />
      </audio>
      <Recorder />
      <br />
      <Footer />
    </>

  )
}

export default App
