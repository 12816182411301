import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import FlagIcon from '../assets/greece.png'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import * as sdk from "microsoft-cognitiveservices-speech-sdk";

import Grid from "@material-ui/core/Grid";
// import { PromiseResultEventSource } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/Promise'
// var gaudio = new Audio()

const useStyles = makeStyles(theme => ({

    paper: {
        width: '100%',
        overflowX: 'auto'

    },


    pink: {

        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    textField: {

        padding: theme.spacing(0.5),

    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}))

var player = new sdk.SpeakerAudioDestination();

export default (props) => {
    const classes = useStyles()
    const [play, toggleplay] = React.useState(false)
    const [col, setCol] = React.useState("primary")
    const speechConfig = sdk.SpeechConfig.fromSubscription("f5bec57302dd4d69971004ad61e339f2", "northeurope");
    speechConfig.speechSynthesisLanguage = "el-GR"
    speechConfig.speechSynthesisVoiceName = "el-GR-NestorasNeural"
    
    var audioConfig  = sdk.AudioConfig.fromSpeakerOutput(player);
    player.onAudioEnd=function(s,e){
        setCol('primary')
        toggleplay(false)
                };
    const synthesizeSpeech=() =>{
        
        
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig,audioConfig);
        
        synthesizer.speakTextAsync(
            props.Text,
            result => {
                synthesizer.close(()=>{
                    
                });
                
               
                
                
                
                // return result.audioData;
            },
            error => {
                console.log(error);
                synthesizer.close(()=>{
                    console.log("complete")
                });
            })
            // synthesizer.synthesisCompleted=()=>{
            //     console.log("complete")
            // }
    }


    const playPause = () => {
       
        play ? setCol("primary") : setCol("secondary")
        
        toggleplay(!play)
    }

    const playPauseIconRenderer=()=>{
        if (!props.uploadProgress){

        return     play ?<IconButton
         color={col}
         onClick={e => {
           playPause();
         }}
       >
         <RecordVoiceOverIcon  fontSize={'large'}/>
       </IconButton>:  <IconButton
         color={col}
         onClick={e => {
           playPause();
         }}
       >
         <RecordVoiceOverIcon  fontSize={'large'}/>
       </IconButton> 
        }
         
  }

    React.useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            const startPlayer = () => {
                
                
                synthesizeSpeech()
                
             
            }


            if (play) {
                startPlayer()
            } else {
                
                
                player.pause()
                player = new sdk.SpeakerAudioDestination()

            }
        }

        return () => isSubscribed = false
    }, [play, props.Text])
    return (

        <Paper elevation={5} className={classes.paper} >
                        
            <Grid container spacing={0}>
        <Grid item xs={1}>
        <Avatar alt="Greek Letter" className={classes.large} src={FlagIcon}></Avatar>
        </Grid>
        <Grid item xs={1}>
         {playPauseIconRenderer()} 
        </Grid>
      </Grid>
                    <TextField
                        className={classes.textField}
                        id="Greek"
                        label="Greek Text"
                        variant="outlined"
                        fullWidth
                        multiline={true}

                        value={props.Text}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                            style: { fontSize: 25 },
                            
                        }}
                    />
        </Paper>
    )
}