import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import logo from '../assets/logo.png'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white'
  }
}))

export default () => {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <AppBar position='static'>
        <Toolbar>
          <Avatar alt='Sutherland' src={logo} className={classes.avatar} />
          <Typography variant='h6' color='inherit' noWrap>
                        Intelligent Voice Assistant(IVA)
          </Typography>
        </Toolbar>
      </AppBar>
    </>

  )
}
