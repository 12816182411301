import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { KeyPhrases } from '../libs/CognitiveCloud'
import { TagCloud } from 'react-tagcloud'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    verticalAlign: 'middle'
  },

  title: {
    fontSize: 14
  },
  lable: {
    marginLeft: '61px',
    paddingLeft: '39px'

  }

})
)

export default (props) => {
  const classes = useStyles()
  const [cloud, setCloud] = React.useState([{ value: 'Nothing to display yet.', count: 20 }])
  const [last, setLast] = React.useState('')
  const CoreElement = () => {
    if (props.Text && cloud.length > 1) {
      return (
        <Paper className={classes.root}>
          <TagCloud
            minSize={20}
            maxSize={60}
            tags={cloud}

          />
        </Paper>
      )
    } else {
      return <Skeleton width='100%' height={400} />
    }
  }

  const getRandom = (from, to) => {
    return Math.floor(Math.random() * (to - from + 1)) + from
  }
  React.useEffect(() => {
    let isSubscribed = true
    if (props.Text !== last && props.Text.length >= 1) {
      KeyPhrases(props.Text).then(data => {
        if (isSubscribed) {
          setLast(props.Text)
          const Phrases = data.KeyPhrases.map((x, idx) => {
            return { value: x.Text, count: getRandom(20, 60), key: idx }
          })
          setCloud(Phrases)
        }
      }).catch(err => console.error(err))
    }
    return () => isSubscribed = false
  }, [props.Text, cloud, setCloud, last])
  return (
    <CoreElement />
  )
}
