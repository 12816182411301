import React from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

const theme = createMuiTheme({
  typography: {
    subtitle1: {
      fontSize: 20
    }

  }
})
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 1),

    verticalAlign: 'middle'
  },

  title: {
    fontSize: 14
  },
  lable: {
    marginLeft: '61px',
    paddingLeft: '39px'

  },
  magnitude: {
    backgroundColor: '#29b6f6',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '5px',
    color: 'white'
  },
  positive: {
    backgroundColor: '#388e3c',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '5px',
    color: 'white'

  },
  negative: {
    backgroundColor: '#e53935',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '5px',
    color: 'white'

  },
  neutral: {
    backgroundColor: '#ffe57f',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '5px',
    color: 'black'

  },
  rangePaper: {
    backgroundColor: theme.palette.background.paper
  }
})
)
export default (props) => {
  const classes = useStyles()

  const scoreColor = (number) => {
    switch (true) {
      case number >= 0.25 && number < 1.0:

        return classes.positive
      case number >= -0.25 && number < 0.25:

        return classes.neutral

      case number >= -1.0 && number < -0.25:

        return classes.negative

      default:
        return classes.neutral
    }
  }

  const Elements = () => {
    let items = []
    const pairs = []
    if (props.sentence && props.sentence.length >= 1) {
      const sentencelist = props.sentence
      var i; var j; var temparray; var chunk = 2
      for (i = 0, j = sentencelist.length; i < j; i += chunk) {
        temparray = sentencelist.slice(i, i + chunk)

        pairs.push(temparray)
      }
      items = pairs.map((x, idx) => {
        if (x.length === 2) {
          return (

            <div key={idx}>

              <Grid className={classes.root} container align='center' justify='space-between' spacing={1}>

                <Grid item xs={6} align='left'>

                  <Card elevation={5} key={idx} className={classes.root}>

                    <CardContent>
                      <ThemeProvider theme={theme}>

                        <Typography variant='subtitle1'>{x[0].text.content}</Typography>
                      </ThemeProvider>

                      <Grid container spacing={1}>
                        <Grid item xs={3}>

                          <Typography variant='subtitle2'>Score</Typography>
                          <Box align='center' className={scoreColor(x[0].sentiment.score)}>
                                      <strong>{x[0].sentiment.score}</strong>

                                    </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='subtitle2'>Magnitude</Typography>
                          <Box align='center' className={classes.magnitude}>
                                      <strong>{x[0].sentiment.magnitude}</strong>
                                    </Box>
                        </Grid>

                      </Grid>
                    </CardContent>

                  </Card>

                </Grid>

                <Grid item xs={6} align='left'>

                  <Card elevation={4} key={idx} className={classes.root}>

                    <CardContent>
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>{x[1].text.content}</Typography>
                      </ThemeProvider>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>

                          <Typography variant='subtitle2'>Score</Typography>
                          <Box align='center' className={scoreColor(x[1].sentiment.score)}>
                                      <strong>{x[1].sentiment.score}</strong>

                                    </Box>
                        </Grid>
                        <Grid item xs={3}>

                          <Typography variant='subtitle2'>Magnitude</Typography>
                          <Box align='center' className={classes.magnitude}>
                                      <strong>{x[1].sentiment.magnitude}</strong>
                                    </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                </Grid>

              </Grid>

              <br />
            </div>

          )
        } else {
          console.log('part2')
          return (

            <div key={idx}>
              <Card elevation={4} key={idx} className={classes.root}>

                <CardContent>
                  <Grid className={classes.root} container align='center' justify='space-between' spacing={1}>

                    <Grid item xs={6} align='left'>
                      <ThemeProvider theme={theme}>

                        <Typography variant='subtitle1'>{x[0].text.content}</Typography>
                      </ThemeProvider>

                      <Grid container spacing={3}>
                        <Grid item xs={3}>

                          <Typography variant='subtitle2'>Score</Typography>
                          <Box align='center' className={scoreColor(x[0].sentiment.score)}>
                            <strong>{x[0].sentiment.score}</strong>

                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='subtitle2'>Magnitude</Typography>
                          <Box align='center' className={classes.magnitude}>
                            <strong>{x[0].sentiment.magnitude}</strong>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
              <br />
            </div>

          )
        }
      })

      return items
    } else {
      return <Skeleton width='100%' height={200} />
    }
  }

  return (

    <>
      {props.sentence.length >= 1 ? <Elements /> : <Skeleton />}

    </>
  )
}
