
// ---------------------------------------------------------------------------------------------------

var request = require('request')

// // Set the headers
// var headers = {
//     'User-Agent':       'Super Agent/0.0.1',
//     'Content-Type':     'application/x-www-form-urlencoded'
// }

// Configure the request
// var options = {
//     url: 'http://smartairlinesdbconnect.us-east-1.elasticbeanstalk.com/TotalRecords',
//     method: 'POST',

// }

// Start the request

const sendRequest = (url, body) => {
  return new Promise((resolve, reject) => {
    var options = {
      url: url,
      method: 'POST',
      json: true,
      body: body
    }

    console.log(options)
    request(options, function (error, response, body) {
      if (!error && response.statusCode === 200) {
        // Print out the response body

        resolve(body)
      } else if (error || response.statusCode !== 200) {
        // debugger;
        console.log('something went wrong')
        console.log('error ' + error)
        console.log('response ' + response)
        reject(error)
      } else {
        reject(error)
      }
    })
  })
}

export default sendRequest
