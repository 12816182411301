import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import FlagIcon from '../assets/english.png'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { Talk } from '../libs/CognitiveCloud'
import Grid from "@material-ui/core/Grid";
var gaudio = new Audio()

const useStyles = makeStyles(theme => ({

    paper: {
        width: '100%',
        overflowX: 'auto'

    },


    pink: {

        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    textField: {

        padding: theme.spacing(0.5),

    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}))



export default (props) => {
    const classes = useStyles()
    const [play, toggleplay] = React.useState(false)
    const [col, setCol] = React.useState("primary")
   

    const playPause = () => {
       
        play ? setCol("primary") : setCol("secondary")
        
        toggleplay(!play)
    }

    const playPauseIconRenderer=()=>{
        if (!props.uploadProgress){

        return     play ?<IconButton
         color={col}
         onClick={e => {
           playPause();
         }}
       >
         <RecordVoiceOverIcon  fontSize={'large'}/>
       </IconButton>:  <IconButton
         color={col}
         onClick={e => {
           playPause();
         }}
       >
         <RecordVoiceOverIcon  fontSize={'large'}/>
       </IconButton> 
        }
         
  }

    React.useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            const startPlayer = () => {
                Talk(props.Text, "english").then(url => {
                    let audio = new Audio(url)
                    gaudio = audio
                    audio.play()
                    audio.onended = () => {
                        setCol("primary")
                        toggleplay(false)
                    }
                }).catch(err => console.log(err))

            }


            if (play) {
                startPlayer()
            } else {
                gaudio.pause()

            }
        }

        return () => isSubscribed = false
    }, [play, props.Text])
    return (

        <Paper elevation={5} className={classes.paper} >
                        
            <Grid container spacing={0}>
        <Grid item xs={1}>
        <Avatar alt="English Letter" className={classes.large} src={FlagIcon}></Avatar>
        </Grid>
        <Grid item xs={1}>
        {playPauseIconRenderer()}
        </Grid>
      </Grid>
                    <TextField
                        className={classes.textField}
                        id="English"
                        label="English Text"
                        variant="outlined"
                        fullWidth
                        multiline={true}

                        value={props.Text}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                            style: { fontSize: 25 },
                            
                        }}
                    />
        </Paper>
    )
}