import React, { useState, useEffect } from "react";

// import Highlighter from "react-highlight-words";
import "../assets/css/highlight.css"
import Hoc from "./Hoc/hoc"
import {Typography } from '@material-ui/core'


export default (props) => {
  // <p>Do not forget to buy <mark>milk</mark> today.</p>

  const [extractedwords, setExtractedwords] = useState([]);

  useEffect(() => {
    setExtractedwords(props.extracted);
  }, [props.extracted]);

  const Componet = () => {
    const lastText = props.Text;
    //    console.log(typeof lastText)
    const splitText = lastText.split(" ");

    splitText.forEach((element, i) => {
      const match = extractedwords.filter(
        (x) =>
          x.word.toLowerCase() ===
          element.toLowerCase().replace(/[^a-zA-Z ]/g, "")
      );

      if (match.length > 0) {
        splitText[i] = (
          <span key={i} style={{ backgroundColor: match[0].color }}>
            {" " + match[0].word + " "}
          </span>
        );
      } else {
        splitText[i] = <Hoc key={i}>{" " + element + " "}</Hoc>;
      }
    });

    //    console.log(splitText)
    //    console.dir(content)
    return (
      <Hoc>
        <Typography variant="subtitle1">{splitText}</Typography>
      </Hoc>
    );
  };

  return <Componet />;
};
