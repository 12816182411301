import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import FaceIcon from '@material-ui/icons/Face'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import EventIcon from '@material-ui/icons/Event'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import TitleIcon from '@material-ui/icons/Title'
import DescriptionIcon from '@material-ui/icons/Description'
import Skeleton from '@material-ui/lab/Skeleton'
import { Entities } from '../libs/CognitiveCloud'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),

    verticalAlign: 'middle'
  },
  entityType: {
    borderRadius: '2px',
    color: '#fff',
    float: 'right',
    margin: '-16px -15px',
    padding: '6px 16px'
  }
})
)

export default (props) => {
  const classes = useStyles()

  const [status, setStatus] = React.useState(false)
  const [person, setPerson] = React.useState([])
  const [location, setLocation] = React.useState([])
  const [organization, setOrganization] = React.useState([])
  const [commercial, setCommercial] = React.useState([])
  const [events, setEvents] = React.useState([])
  const [dates, setDates] = React.useState([])
  const [quantity, setQuantity] = React.useState([])
  const [titles, setTitles] = React.useState([])
  const [others, setOthers] = React.useState([])

  React.useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      const entityColor = (entity) => {
        switch (entity) {
          case 'PERSON':
            return '#ea4335'
          case 'LOCATION':

            return '#34a853'
          case 'ORGANIZATION':

            return '#4285f4'
          case 'EVENT':

            return '#f29900'
          case 'DATE':

            return '#f538a0'
          case 'COMMERCIAL_ITEM':

            return '#b31412'
          case 'QUANTITY':

            return '#185abc'
          case 'TITLE':

            return '#a142f4'
          case 'OTHER':

            return '#9aa0a6'

          default:
            // this.setState({ others: listArray })
            return '#9aa0a6'
        }
      }

      const getChipIcon = (entity) => {
        switch (entity) {
          case 'PERSON':
            return <FaceIcon />
          case 'LOCATION':

            return <LocationOnIcon />
          case 'ORGANIZATION':

            return <LocationCityIcon />
          case 'COMMERCIAL_ITEM':
            return <LocalMallIcon />
          case 'EVENT':

            return <EventIcon />
          case 'DATE':

            return <CalendarTodayIcon />
          case 'QUANTITY':

            return <FitnessCenterIcon />
          case 'TITLE':

            return <TitleIcon />

          case 'OTHER':

            return <DescriptionIcon />

          default:
            // this.setState({ others: listArray })
            return <DescriptionIcon />
        }
      }

      const getChip = (x, idx) => {
        return (
          <Chip key={idx} label={x.Text} color='primary' icon={getChipIcon(x.Type)} />
        )
      }
      const setFilter = (data, filter, setter) => {
        const lfilter = data.Entities.filter((x) => {
          return x.Type === filter
        })
        const chips = lfilter.map((x, idx) => {
          return getChip(x, idx)
        })
        const final = <>
          <Card className={classes.card}>
            <CardContent>
              <Box style={{ backgroundColor: entityColor(filter) }} className={classes.entityType}>{filter}</Box>
              {chips}
            </CardContent>
          </Card>
          <br />
        </>
        setter(final)
      }

      const filterEntities = (data) => {
        data.Entities.length >= 1 ? setStatus(true) : setStatus(false)
        data.Entities.forEach(item => {
          switch (item.Type) {
            case 'PERSON':
              setFilter(data, 'PERSON', setPerson)

              break
            case 'LOCATION':
              setFilter(data, 'LOCATION', setLocation)
              break
            case 'ORGANIZATION':
              setFilter(data, 'ORGANIZATION', setOrganization)
              break
            case 'COMMERCIAL_ITEM':
              setFilter(data, 'COMMERCIAL_ITEM', setCommercial)
              break
            case 'EVENT':
              setFilter(data, 'EVENT', setEvents)
              break
            case 'DATE':
              setFilter(data, 'DATE', setDates)
              break
            case 'QUANTITY':
              setFilter(data, 'QUANTITY', setQuantity)
              break
            case 'TITLE':
              setFilter(data, 'TITLE', setTitles)
              break
            case 'OTHER':
              setFilter(data, 'OTHER', setOthers)
              break

            default:
              // this.setState({ others: listArray })
              break
          }
        })
      }

      if (props.Text.length >= 1) {
        Entities(props.Text).then(data => {
          filterEntities(data)
        }).catch(err => console.error(err))
      }
    }

    return () => isSubscribed = false
  }, [props.Text, classes.card, classes.entityType])

  const CoreElement = () => {
    if (props.Text && status) {
      return (
        <>
          <Paper className={classes.root}>
            {person || ''}
            {location || ''}
            {organization || ''}
            {commercial || ''}
            {events || ''}
            {dates || ''}
            {quantity || ''}
            {titles || ''}
            {others || ''}
          </Paper>
        </>
      )
    } else {
      return <Skeleton width='100%' height={300} />
    }
  }
  // React.useEffect(() => {

  // })
  return (
    <CoreElement />
  )
}
