import React, { useState } from "react";

import Hoc from "./Hoc/hoc";
import { Card, CardContent, Paper,  Typography  } from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";

import post from "./post/post";
import Highligted from "./Highlighted";

import { useEffect } from "react";
import { ListItem } from "@material-ui/core";
import { List } from "@material-ui/core";

import "../assets/css/verticalStripe.css";
const _ = require("lodash");
const randomColor = require("randomcolor");

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    backgroundColor: theme.palette.background.paper
  },
  label: {
    marginLeft: '102px'
  },
  title: {
    fontSize: 14
  },
  lable: {
    marginLeft: '61px',
    paddingLeft: '39px'
  },
  magnitude: {
    backgroundColor: '#29b6f6',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '15px',
    color: 'white'
  },
  scoreRange: {
    width: '100%',
    padding: '7px',
    marginTop: '16px'
  },
  rangePositive: {
    backgroundColor: '#388e3c',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'white'
  },
  rangeNegative: {
    backgroundColor: '#e53935',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'white'
  },
  rangeNeutral: {
    backgroundColor: '#ffe57f',
    borderRadius: '3px 0 0 3px',
    width: '100%',
    padding: '7px',
    marginTop: '16px',
    color: 'black'
  },
  neutral: {
    borderLeft: '6px solid yellow',
    height: '28px',
    width: '6px',
    margin: '0px 10px 0px 0px',
    float: 'left'
  },
  positive: {
    borderLeft: '6px solid green',
    height: '28px',
    width: '6px',
    margin: '0px 10px 0px 0px',
    float: 'left'
  },
  negative: {
    borderLeft: '6px solid red',
    height: '28px',
    width: '6px',
    margin: '0px 10px 0px 0px',
    float: 'left'
  },
  paper: {
    padding: '5px'
  },
  rangePaper: {
    backgroundColor: theme.palette.background.paper
  }
}))

export default (props) => {
  const classes = useStyles()

  const [extractedWords, setExtractedWords] = useState([])

  useEffect(() => {
    const payload = {}
    payload.keyname = '0000'
    payload.sentence = props.Text
    /// //////////////

    //
    /// /////////////

    post(
      'https://9hgp8bi8a1.execute-api.us-east-1.amazonaws.com/default/keywordreg',
      payload
    )
      .then((data) => {
        const constructedJson = { words: [] }
        data.words_matched.forEach((each, i) => {
          constructedJson.words.push({
            word: each,
            matches: data.detected_word[i],
            confidence: data.conf_score[i] * 100,
            count: _.countBy(data.words_matched, (e) => {
              return e === each
            }).true
          })
        })
        const UniqueConstructedJson = _.uniqBy(
          constructedJson.words,
          (each) => {
            return each.word
          }
        )
        const conFidenceSortedUniqueConstructedJson = _.orderBy(
          UniqueConstructedJson,
          ['confidence'],
          ['desc']
        )
        const colorassignedConFidenceSortedUniqueConstructedJson = conFidenceSortedUniqueConstructedJson.map(
          (each) => {
            each.color = randomColor({ luminosity: 'light' })
            return each
          }
        )
        console.log(colorassignedConFidenceSortedUniqueConstructedJson)
        setExtractedWords(colorassignedConFidenceSortedUniqueConstructedJson)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [props.Text])

  useEffect(() => {
    // console.log(highlightedWordReferance)
  }, [])
  const matchCards = () => {
    if (extractedWords.length > 0) {
      return extractedWords.map((each, i) => {
        return (
          <Card key={i}>
            <CardContent className={classes.paper}>
              <Paper elevation={3}>
                {(() => {
                  return (
                    <Typography variant='subtitle1'>
                      <div
                        style={{
                          borderLeft: '6px solid ',
                          borderColor: each.color,
                          height: '28px',
                          width: '6px',
                          margin: '0px 10px 0px 0px',
                          float: 'left'
                        }}
                      />
                      <strong>
                        {each.word.charAt(0).toUpperCase() + each.word.slice(1)}
                      </strong>{' '}
                      matches {each.matches} by {each.confidence} % and occurs{' '}
                      {each.count} {each.count > 1 ? 'times' : 'time'}
                    </Typography>
                  )
                })()}
              </Paper>
            </CardContent>
          </Card>
        )
      })
    } else {
      return <Skeleton variant='rect' width='100%' height={100} />
    }
  }

  return (
    <Hoc>
      <List className={classes.root} aria-label='mailbox folders'>
        <Card>
          <CardContent>
            <ListItem>
              <Highligted
                Text={props.Text}
                extracted={extractedWords}
              />
            </ListItem>
          </CardContent>
        </Card>
        {matchCards()}
      </List>
    </Hoc>
  )
}
