const googleTranslate = require("google-translate")(
  process.env.REACT_APP_GCP_TRANSLATE
);
const axios = require("axios");
const AWS = require("aws-sdk");
AWS.config.region = process.env.REACT_APP_AWS_REGION;
AWS.config.credentials = new AWS.Credentials(
  process.env.REACT_APP_AWS_KEY,
  process.env.REACT_APP_AWS_SECRET
);
const comprehend = new AWS.Comprehend({ apiVersion: "2017-11-27" });
const Polly = new AWS.Polly({
  signatureVersion: "v4",
  region: "us-east-1",
});

const url = `https://language.googleapis.com/v1/documents:analyzeSentiment?key=${process.env.REACT_APP_GCP_TRANSLATE}`;

exports.SentimentAnalysis = (text) => {
  console.log(text)
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        document: {
          type: "PLAIN_TEXT",
          content: text,
        },
        encodingType: "UTF8",
      })
      .then((res) => {
        resolve({
          result: res.data.documentSentiment,
          sentence: res.data.sentences,
        });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

exports.Translate = (textToTranslate, language) => {
  return new Promise((resolve, reject) => {
    googleTranslate.translate(textToTranslate, language, (err, translate) => {
      if (err) reject(err);
      resolve(
        translate.hasOwnProperty("translatedText")
          ? translate.translatedText
          : ""
      );
    });
  });
};

exports.Sentiment = (text) => {
  return new Promise((resolve, reject) => {
    let params = {
      LanguageCode: "en" /* required */,
      Text: text /* required */,
    };
    comprehend.detectSentiment(params, (err, data) => {
      if (err) reject(err);
      else resolve(data.Sentiment);
    });
  });
};

exports.Talk = (text, lang) => {
  return new Promise((resolve, reject) => {
    let params = {
      OutputFormat: "mp3",
      SampleRate: "16000",
      Text: text,
      TextType: "text",
    };
    switch (lang) {
      case "arab":
        params.VoiceId = "Zeina";
        break;
      case "spanish":
        params.VoiceId = "Conchita";
        break;
      case "german":
        params.VoiceId = "Marlene";
        break;
      case "english":
        params.VoiceId = "Joanna";
        break;
      case "greek":
        console.log('greek')
        params.VoiceId = "Nestoras";
        break;
      default:
        break;
    }
    const Signer = new AWS.Polly.Presigner(params, Polly);

    Signer.getSynthesizeSpeechUrl(params, (err, url) => {
      if (err) reject(err);
      else resolve(url);
    });
  });
};

exports.KeyPhrases = (text) => {
  return new Promise((resolve, reject) => {
    if (text.length < 1) reject("length should be more than one.");

    let params = {
      LanguageCode: "en" /* required */,
      Text: text /* required */,
    };
    comprehend.detectKeyPhrases(params, (err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });
};

exports.Entities = (text) => {
  return new Promise((resolve, reject) => {
    let params = {
      LanguageCode: "en" /* required */,
      Text: text /* required */,
    };
    comprehend.detectEntities(params, (err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });
};
