import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PieChartIcon from '@material-ui/icons/PieChart'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Analyze from './Analyze'
import Convert from "./convert"
import Contextualize from './Contextualize'
import Extract from './Extract'
import FindWord from "./FindWord"
import WordList from "./WordList"
import TranslateIcon from '@material-ui/icons/Translate'
import GrainIcon from '@material-ui/icons/Grain'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BookIcon from '@material-ui/icons/Book';


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}))

export default (props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }





  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='Core content'
        >
          <Tab label='Translate'  icon={<TranslateIcon />} {...a11yProps(0)} />
          <Tab label='Analyze'  icon={<PieChartIcon />} {...a11yProps(1)} />
          <Tab label='Contextualize' icon={<AccountTreeIcon />} {...a11yProps(2)} />
          <Tab label='Entities'  icon={<GrainIcon />} {...a11yProps(3)} />
          <Tab label='word finder'  icon={<FindInPageIcon />} {...a11yProps(4)} />
          <Tab label='word repo'  icon={<BookIcon />} {...a11yProps(5)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Convert Text={props.Text}
        uploadProgress={props.uploadProgress}
        renderMicrophone={props.renderMicrophone}
        text={props.text} 
        typingInProgress={props.typingInProgress} 
        typingFocus={props.typingFocus} 
        typingInput={props.typingInput} 
        doneTyping={props.doneTyping}
        arab={props.arab} 
        spain={props.spain} 
        german={props.german}
        greek={props.greek}
        english={props.english}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Analyze Text={props.Text} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Contextualize Text={props.Text} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Extract Text={props.Text} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FindWord Text={props.Text} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <WordList></WordList>
      </TabPanel>
    </div>
  )
}
